// colors
$primary-color: #61a2ac;
$primary-color-rgb: 97, 162, 172;
// $primary-color: #E30613;
// $primary-color-rgb: 227, 6, 19;
$primary-color-contrast: #ffffff;
$primary-color-contrast-rgb: 255, 255, 255;

$secondary-color: #747474;
$secondary-color-rgb: 116, 116, 116;
$secondary-color-contrast: #ffffff;
$secondary-color-contrast-rgb: 255, 255, 255;

$warning-color: #ffce00;
$warning-color-rgb: 255, 206, 0;
$warning-color-contrast: #ffffff;
$warning-color-contrast-rgb: 255, 255, 255;

$danger-color: #d21034;
$danger-color-rgb: 210, 16, 52;
$danger-color-contrast: #ffffff;
$danger-color-contrast-rgb: 255, 255, 255;

$dark-color: #878787;
$dark-color-rgb: 135, 135, 135;
$dark-color-contrast: #ffffff;
$dark-color-contrast-rgb: 255, 255, 255;

$medium-color: #c6c6c6;
$medium-color-rgb: 198, 198, 198;
$medium-color-contrast: #ffffff;
$medium-color-contrast-rgb: 255, 255, 255;

$light-color: #CCCCCC;
$light-color-rgb: 204, 204, 204;
$light-color-contrast: #000000;
$light-color-contrast-rgb: 0, 0, 0;

// fonts
$default-font: "Roboto", sans-serif;
$default-font-size: 16px;

// left menu
$default-logo-file: 'images/theme/logo.jpg';
$bg-menu-color: #ffffff;
$bg-menu-image: 'bg-menu.png';
$menu-color: #1e1e1e;
$menu-color-highlight: lighten($menu-color, 25%);
$color-memu-highlight: #181818;
$bg-overlay: #ffffff;
$bg-overlay-rgb: 255, 255, 255;

// dashboard
$bg-dashboard-color: #f39100;
$bg-dashboard-image: 'bg-dashboard.png';

// main content
$bg-content: #F8F8F8;
$bg-content-contrast: #FFFFFF;
$color-content: $dark-color;
$bg-content-logged-out: #FFFFFF;
$color-content-logged-out: $dark-color;

// global borders
$global-radius: 4px;
$global-padding: 16px;
$global-margin: 16px;

// inputs
$input-background: #FFFFFF;
$input-color: $dark-color;
$input-placeholder-color: #999999;
$input-border-color: #E7E7E7;//$light-color;
$input-height: 48px;

$form-width: 448px;


// buttons
$button-height: 48px;

:root {
    --theme-menu-opacity: linear-gradient(0deg, rgba(#{$bg-overlay-rgb},1) 0%, rgba(#{$bg-overlay-rgb},0.4) 20%, rgba(#{$bg-overlay-rgb},0.4) 85%, rgba(#{$bg-overlay-rgb},0.6) 100%);
    --ion-logo-menu: url('/assets/images/theme/logo.png') !important;
}

ion-menu ion-content .event-switch span {
    color: var(--ion-color-light) !important;
}

ion-menu ion-content .event-switch span svg-icon {
    fill: var(--ion-color-light) !important;
}

.header-overlay a {
    color: var(--ion-color-light) !important;
}